@media screen and (max-width:640px) {
    .test {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 100%;
    }

    .test>div.col-md-3.left {
        box-sizing: border-box;
        position: relative;
        width: 285px;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 100% !important;
        float: left;
        color: rgb(51, 51, 51);
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-size: 14px;
        font-style: normal;
        font-variant-ligatures: normal;
        font-variant-caps: normal;
        font-weight: 300;
        letter-spacing: normal;
        orphans: 2;
        text-align: left;
        text-indent: 0px;
        text-transform: none;
        widows: 2;
        word-spacing: 0px;
        -webkit-text-stroke-width: 0px;
        white-space: normal;
        background-color: rgb(255, 255, 255);
        text-decoration-thickness: initial;
        text-decoration-style: initial;
        text-decoration-color: initial;
    }


    .test>div.col-md-9.right>span>div:nth-child(2) {
        box-sizing: border-box;
        position: relative;
        width: 825px;
        min-height: 1px;
        padding-right: 0px;
        padding-left: 0px;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        float: left;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .test>div.col-md-9.right>span>div:nth-child(2)>div.col-md-7 {
        box-sizing: border-box;
        position: relative;
        width: 481.237px;
        min-height: 1px;
        padding-right: 0px;
        padding-left: 0px;
        -webkit-box-flex: 0;
        flex: 0 0 58.3333%;
        max-width: 100% !important;
        float: left;
    }

    .test>div.col-md-9.right>span>div:nth-child(8) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .test>div.col-md-9.right>span>div:nth-child(8)>div.col-md-7 {
        max-width: 100%;
    }

    .test>div.col-md-9.right>span>div:nth-child(2)>div:nth-child(2) {
        box-sizing: border-box;
        position: relative;
        width: 412.5px;
        min-height: 1px;
        padding-right: 0px;
        padding-left: 0px;
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 100% !important;
        float: left;
    }

    .test>div.col-md-9.right>span>div:nth-child(7) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .test>div.col-md-9.right>span>div:nth-child(7)>div:nth-child(2) {
        max-width: 100% !important;
    }

    .test>div.col-md-9.right {
        max-width: 100% !important;
        overflow: hidden !important;
    }
}