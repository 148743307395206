body {
    margin: 0;
}

/* *********************** Top-Bar **************************** */
.topbar {
    display: flex;
    justify-content: space-between;
    height: 31px;
    align-items: center;
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
    color: white;
    padding: 10px 20px;
}

.topbar-left-section p {
    margin: 70px;
}

.topbar-right-section {
    display: flex;
    gap: 15px;
}

.topbar-phone-link {
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
}

.topbar-phone-link .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

/* *********************** Nav-Bar **************************** */


.navbar {
    background-color: #333;
    color: #fff;
    padding: 10px 30px;
    background-color: white;
}

.navbar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo img {
    height: 80px;
    margin-right: 20px;
    padding-left: 50px;
}

.navbar p {
    font-weight: bold;
    font-family: -webkit-body;
    color: #0d0f0f;
    font-size: 25px;
    margin: 0;
}

.nav-links {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 20px;
}

.nav-links li {
    display: inline;
}

.nav-links a {
    color: #000000;
    padding: 10px;
    line-height: 20px;
    font-size: 20px;
    text-decoration: none;
}

.nav-links a:hover {
    color: #3ac5c8;
}

.navbar-list {
    position: relative;
}

.navbar-list:hover>div {

    display: block;
}

.navbar-list>div {

    display: none;
    width: 250px;
    position: absolute;
    top: 35px;
    left: -150px;
    z-index: 5;
    background-color: white;
    color: black;
    border-top: 5px solid #3AC5C8;
}


.navbar-list>div>div {

    padding: 0px 10px;
    /* border: 1px solid black; */
    border-bottom: 0px;
    position: relative;
}


.nav-relative {
    background: rgb(255, 255, 255);
    position: relative;

}

.dropdown {
    box-shadow: 2px 2px 10px black;
}


.nav-relative>div {
    display: none;
    position: absolute;
    top: 5px;
    left: 210px;
    z-index: 6;
    /* border: 0.5px solid rgb(103, 99, 99);
 */
    box-shadow: 2px 2px 10px black;
    width: 200px;
}

.nav-relative:hover>div {

    display: block;
    background-color: rgb(255, 255, 255);
}

a {
    cursor: pointer;
}

/* ******************************************** Homepage ***************************************************** */

@keyframes fadeInRight {
    0% {
        opacity: 0;
        transform: translateX(20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.slider {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    overflow: hidden;
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
}

.slide {
    display: flex;
    justify-content: space-between;
}

.slide-image {
    margin-left: 10px;
    display: flex;
    width: 300px;
    height: auto;
    align-items: center;
    justify-content: center;
}

.slide-image img {
    width: 100%;
    height: auto;
}

.slider h2 {
    font-size: 60px;
    color: white;
    line-height: 10p;
    animation: fadeInRight 1s ease forwards;
}

.slider p {
    font-size: 25px;
    color: white;
    animation: fadeInRight 1s ease forwards;
    animation-delay: 0.2s;
}

.slide-content {
    flex: 1;
    padding: 100px;
    /* line-height: 20px; */
}

.slider button {
    position: absolute;
    background: white;
    color: black;
    font-size: 18px;
    border: none;
    border-radius: 30px;
    width: 170px;
    height: 52px;
    animation: fadeInRight 1s ease forwards;
    animation-delay: 0.4s;
}

.slider button:hover {
    transition: .7s all ease;
    background-color: #0e55a1;
    color: white;

}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}


/* *********************** Home-panel1 **************************** */

.home-panel {
    text-align: center;
    padding: 20px;
}

.top-content {
    margin-bottom: 30px;
}

.heading {
    font-size: 2.7em;
    margin: 0;
    color: #3ac5c8 !important;
    text-transform: uppercase;
}

.paragraph {
    font-size: 1.5em;
    color: #666;
}

.sections {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.left-section,
.right-section {
    flex: 1;
}

.left-section {
    text-align: left;
}

.section-paragraph {
    font-size: 1.3em;
    margin-bottom: 10px;
    color: rgb(126, 124, 124);
    line-height: 1.5;
    margin-left: 100px;
}

.learn-more-button {
    padding: 10px 20px;
    font-size: 1.6em;
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
    background-color: transparent;
    color: white;
    border: 1px solid #1466a6;
    border-radius: 20px;
    cursor: pointer;
    margin-left: 100px;
}

.learn-more-button:hover {
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
}

.section-image {
    max-width: 100%;
    height: 230px;
    border-radius: 5px;
    margin-right: 250px;
}

/* Fade-in-left animation */
@keyframes fadeInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Fade-in-right animation */
@keyframes fadeInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.left-section {
    text-align: left;
    opacity: 0;
    animation: fadeInLeft 1s ease-out forwards;
}

.right-section {
    text-align: right;
    opacity: 0;
    animation: fadeInRight 1s ease-out forwards;
}

/* *********************** Home-panel2 **************************** */

@keyframes fadeInTop {
    0% {
        opacity: 0;
        transform: translateY(-20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.Home-panel2-container {
    width: 100%;
    height: 725px;
    text-align: center;
}

.Home-panel2-container {
    font-size: 1.3em;
    margin: 0;
    color: #3ac5c8 !important;
    text-transform: uppercase;
}

.Home-panel2-container p {
    color: #666;
}

.Home-panel2-imgs1,
.Home-panel2-imgs2 {
    height: 300px;
    width: auto;
    display: flex;
    justify-content: space-evenly;
    padding-top: 40px;
}

.image-container {
    position: relative;
    width: 300px;
    height: 100%;
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: fadeInTop 1s ease-out;
    border-radius: 8px;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(130, 206, 231, 0.9);
    opacity: 0;
    color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    transition: opacity 0.3s ease;
}

.image-container:hover .overlay {
    opacity: 1;
    border-radius: 8px;
}

.overlay h2 {
    margin: 0;
    font-size: 24px;
}

.overlay p {
    margin: 10px 0;
    font-size: 16px;
}

.view-more {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    text-decoration: none;
    border-radius: 5px;
}

.view-more:hover {
    background-color: #0056b3;
}

/* *********************** Home-panel3 **************************** */

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.Home-panel3 {
    display: flex;
    justify-content: center;
    padding: 20px;
}

.Home-panel3-box1,
.Home-panel3-box2,
.Home-panel3-box3 {
    box-sizing: border-box;
    color: white;
    padding: 15px;
    text-align: center;
    height: auto !important;
    opacity: 0;
    animation: fadeInLeft 0.5s forwards;
}


.Home-panel3-box1 {
    background: #37bdc5;
    height: 430px;
    width: 27%;
    animation-delay: 0.2s;
}

.Home-panel3-box1 h2 {
    font-size: 30px;
    text-transform: uppercase;
}

.Home-panel3-box1 p {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 40px;
    padding-top: 20px;
    color: rgb(233, 230, 230);
}

.Home-panel3-box2 {
    display: block;
    justify-content: center;
    background-color: #343a40 !important;
    width: 30%;
    animation-delay: 0.4s;
}

.Home-panel3-box2 button {
    font-size: 20px;
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
    width: 350px;
    height: 55px;

}

.Home-panel3-box2 button:hover {
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
}

.Home-panel3-box2 h2 {
    font-size: 30px;
    text-transform: uppercase;
}

.Home-panel3-box3 {
    background: #37bdc5;
    height: 430px;
    width: 27%;
    animation-delay: 0.6s;
}

.Home-panel3-box3 h2 {
    font-size: 30px;
    text-transform: uppercase;
}

.Home-panel3-box3 p {
    font-size: 19px;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 40px;
    padding-top: 20px;
    color: rgb(233, 230, 230);
}

.Home-panel3-icon {
    border: 2px solid rgba(255, 255, 255, 0.5);
    width: 100px;
    height: 100px;
    line-height: 80px;
    border-radius: 50%;
    margin: 0 auto !important;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.border-input {
    border: 3px solid rgb(177, 174, 174);
    background: transparent;
    color: rgb(179, 174, 174);
    font-size: 17px;
    padding: 18px;
    width: 100%;
    box-sizing: border-box;

}

.border-input:focus {
    outline: none;
    border-color: #3ac5c8;
}

label {
    font-size: 25px;
    text-align: left;
    padding-left: 60px;
    line-height: 60px;
}

/* *********************** Home-panel4 **************************** */

.Home-panel4 {
    height: auto;
    width: 100%;
    text-align: center;
    color: #3ac5c8 !important;
}

.Home-panel4 h1 {
    padding-left: 0px;
    padding-top: 80px;
    width: 100%;
    font-size: 2.7em;
    margin: 0;
    color: #3ac5c8 !important;
    text-transform: uppercase;
}

.Home-panel4 p {
    color: #9c9a9a;
    font-size: 19px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    line-height: 30px;
}

/* *********************** Home-panel5 **************************** */

.Home-panel5 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 20px;
    width: 90%;
    margin-left: 100px;
}

.Home-panel5-box {
    margin: 10px;
    width: calc(33.33% - 20px);
    text-align: start;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInBottom 0.5s forwards;
    animation-delay: 0.1s;
}

.Home-panel5-box:nth-child(1) {
    animation-delay: 0.1s;
}

.Home-panel5-box:nth-child(2) {
    animation-delay: 0.2s;
}

.Home-panel5-box:nth-child(3) {
    animation-delay: 0.3s;
}

.Home-panel5-box:nth-child(4) {
    animation-delay: 0.4s;
}

.Home-panel5-box:nth-child(5) {
    animation-delay: 0.5s;
}

.Home-panel5-box:nth-child(6) {
    animation-delay: 0.6s;
}

@keyframes fadeInBottom {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.Home-panel5-box img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
}

.Home-panel5-box h2 {
    font-size: 1.9em;
    margin: 10px 0;
    color: rgb(92, 90, 90);
}

.Home-panel5-box p {
    font-size: 1.3em;
    margin: 5px 0;
    color: #757373;
}

.Home-panel5-box a {
    display: inline-block;
    margin-top: 10px;
    font-size: 20px;
    color: #3ac5c8 !important;
    text-decoration: none;
}

/* Responsive styles */
@media (max-width: 900px) {
    .Home-panel5-box {
        width: calc(50% - 20px);
    }
}

@media (max-width: 600px) {
    .Home-panel5-box {
        width: calc(100% - 20px);
    }
}

/* *********************** Home-panel6 **************************** */

@keyframes fadeInLeft {
    0% {
        opacity: 0;
        transform: translateX(-20px);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

.home-panel6-box1,
.home-panel6-box2,
.home-panel6-box3,
.home-panel6-box4 {
    opacity: 0;
    animation: fadeInLeft 0.5s forwards;
}


.home-panel6 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.home-panel6-box {
    width: 23%;
    padding: 15px;
    margin: 10px 0;
    text-align: center;
    color: white;
}

.home-panel6-box1 {
    background: #37bdc5;
    animation-delay: 0.2s;
}

.home-panel6-box2 {
    background: #2795b7;
    animation-delay: 0.4s;
}

.home-panel6-box3 {
    background: #1a75ab;
    animation-delay: 0.6s;
}

.home-panel6-box4 {
    background: #0c539f;
    animation-delay: 0.8s;
}

.home-panel6-box img {
    width: 100px;
    height: 100px;
    line-height: 80px;
    border-radius: 50%;
    margin: 0 auto !important;
}

.home-panel6-box h2 {
    margin: 10px 0;
    font-size: 50px;
}

.home-panel6-box p {
    font-size: 25px;
}

/* *********************** Home-panel7 **************************** */

.Home-panel7 {
    background-color: #f9f9f9;
    padding: 40px 20px;
    text-align: center;
}

.Home-panel7 h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #333;
    color: #3ac5c8 !important;
}

.testimonial-slider {
    position: relative;
    max-width: 800px;
    margin: 0 auto;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.testimonial {
    display: flex;
    align-items: center;
    padding: 20px;
}

.testimonial-image {
    flex: 0 0 100px;
    margin-right: 20px;
}

.testimonial-image img {
    width: 100%;
    border-radius: 50%;

}

.testimonial-content {
    flex: 1;
}

.testimonial-content h3 {
    margin: 0 0 10px;
    font-size: 1.5em;
    color: #2375cc;
}

.testimonial-content p {
    font-size: 1em;
    color: #555;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin: 10px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0056b3;
}



/* *********************** Footer1 **************************** */


footer {
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
    color: #fff;
    padding: 20px;
}

.footer-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.footer-col {
    flex: 1;
    min-width: 200px;
    margin: 10px;
}



.footer-col h3 {
    margin-bottom: 29px;
    font-size: 25px;
    font-weight: 700;
}


a {
    color: #fff;
    text-decoration: none;
    display: block;

    font-size: 20px;
}

.social-icons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.social-icons a {
    font-size: 24px;
    color: #fff;
}

@media (max-width: 600px) {
    .footer-section {
        flex-direction: column;
    }
}

.footer2 {
    text-align: center;
    color: white;
    font-size: 20px;
    border-top: 1px solid white;
}


/* ******************************************** Aboutpage ***************************************************** */

/* *********************** About-panel1 **************************** */

.About-panel1 {
    width: 70%;
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    margin-left: 230px;
}

.left-section {
    flex: 1;
}

.right-section {
    flex: 1;
}

.About-panel1 h2 {
    font-size: 35px;
    color: #248db4;
}

.About-panel1 p {
    font-size: 20px;
    color: #6b6969;
    line-height: 33px;
}

img {
    max-width: 100%;
    height: auto;
}

/* ******************************************** PRODUCTS PAGE *************************************************** */

/* *********************** Products-panel1 **************************** */

.Products-penel1 {
    display: inline-grid;
    margin-left: 50px;
    /* flex-wrap: wrap; */
    /* gap: 10px;  */
    padding: 20px;
}

.Products-penel1-button {
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    font-size: 20px;
    border: 1px solid #ccc;
    border-radius: 15px;
    cursor: pointer;
    transition: .5s ease;
}

.Products-penel1-button:hover {
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
}

.arrow {
    font-size: 20px;
    margin: 0 50px;
}

/* ******************************************** SERVICE PAGE *************************************************** */

/* *********************** service-panel1 **************************** */

.Service-penel1 {
    padding: 20px;
    max-width: 1300px;
    margin: 20px auto;
    text-align: center;
}

.Service-penel1 h1 {
    font-size: 35px;
    margin-bottom: 15px;
    color: #1b6f8d;
}

.Service-penel1 p {
    font-size: 20px;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
}

.Service-penel1 table {
    width: 100%;
    height: 300px;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.Service-penel1 th,
.Service-penel1 td {
    border: 1px solid #c4c0c0;
    text-align: center;
    color: #248db4;
    font-size: 25px;
}

.Service-penel1 button {
    padding: 15px 30px;
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 20px;
}

.Service-penel1 button:hover {
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
}

/* ******************************************** PORTFOLIO PAGE ************************************************* */

/* *********************** Portfolio-panel1 **************************** */

.Portfolio-penel1 {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 30px;
    padding: 100px;
    width: 50%;
    margin-left: 325px;
}

.image-box {
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
}

.image-box img {
    width: 100%;
    height: auto;
}

.image-box a {
    padding: 10px;
    margin: 0;
    color: #5f5d5d;
}

/* ******************************************** Contact PAGE ************************************************* */

/* *********************** Contact-panel1 **************************** */

.Contact-heading {
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    color: #279092 !important;
    padding-top: 50px;
}

.Contact-penel1 {
    display: flex;
    gap: 60px;
    padding: 20px;
    margin-bottom: 80px;
    /* background-color: #f9f9f9; */
    /* border: 1px solid #ccc; */
    /* border-radius: 8px; */
}

.Contact-penel1-headingbox {
    height: 70px;
    width: 100%;
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
    color: white;
}

.Contact-penel1 h1 {
    text-align: center;
    padding-top: 15px;
}

.Contact-penel1 h2 {
    font-size: 30px;
    color: rgb(66, 65, 65);
}

.Contact-penel1 p {
    font-size: 22px;
    color: rgb(97, 95, 95);
}

.Contact-penel1-section {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 650px;
    line-height: 42px;
}

.Contact-penel1 input,
textarea {
    /* width: 100%; */
    margin: 10px 0;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 19px;
}

.Contact-penel1 button {
    display: block;
    width: 200px;
    height: 50px;
    padding: 10px;
    background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
}

.Contact-penel1 .icon {
    margin-right: 5px;
}


.btn-responsive {
    display: none;
}


.right-section {
    display: flex;
    /* width: 140px !important; */
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 30%;

    img {
        margin-right: 0px;
    }
}

.section-paragraph {
    font-size: 15px;
}


.div-for-btn {
    button {
        font-size: 15px;
        padding: 5px 10px;
    }
}


.Home-panel5-box {
    /* border: 1px solid #3ac5c8; */
    padding: 10px;
    border-radius: .3rem;
    transition: background 0.3s ease;
    cursor: default;
    /* Smooth transition for the background */
}

.Home-panel5-box:hover {
    background: linear-gradient(135deg, white, #3ac5c8);
    /* White in top left to #3ac5c8 */
}

@media (max-width: 600px) {
    .topbar-left-section {
        display: none;
    }



    .btn-responsive {
        display: block;
        padding: 0;
        background-color: transparent;
    }

    .topbar {
        padding: 0px;
    }

    .topbar-phone-link {
        font-size: 15px;
    }

    .nav-links {
        display: none;
    }

    .navbar {
        padding-top: 5px;
        padding-bottom: 5px;
        padding: 0px;
    }

    .navbar-logo {
        flex-direction: row;
        align-items: center;
        gap: 4px;
        justify-content: center;

        img {
            margin: 0px;
            padding: 0px;
            height: 40px;
        }

        p {
            font-size: 18px;
            font-style: sans-serif;
        }
    }

    .navbar-container {
        position: relative;
        padding-top: 4px;
        padding-bottom: 4px;
        padding-left: 10px;
    }

    .topbar {
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .nav-links {
        display: block;
        position: absolute;
        z-index: 200;
        background-color: white;
        top: 30px;
        right: 0;

        a {
            line-height: 20px;
            font-size: 15px;
        }
    }

    .desktop {
        display: none;
    }


    .slide {
        flex-direction: row !important;
    }

    .slider {
        padding-left: 15px;
        padding-right: 15px;
        height: 200px;
    }

    .slide-image {
        width: auto;
        padding: 0px;

        img {
            max-width: 100px !important;
            width: 100px !important;
        }
    }

    .slider h2 {
        font-size: 20px !important;
    }

    .slider button {
        margin: 0px;
        height: auto;
        width: auto !important;
        height: auto !important;
        font-size: 12px;
        padding: 0px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .sections {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        flex-direction: column;
        align-items: center;
    }

    .section-paragraph {
        font-size: 15px;
        margin-bottom: 10px;
        color: rgb(126, 124, 124);
        line-height: 1.5;
        margin-left: 0px !important;
    }

    .paragraph {
        font-size: 1rem;
        font-weight: 600;
    }


    .learn-more-button {
        padding: 10px 20px;
        font-size: 1rem;
        background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
        background-color: transparent;
        color: white;
        border: 1px solid #1466a6;
        border-radius: 20px;
        cursor: pointer;
        margin-left: 0px;
    }


    .div-for-btn {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: end;
    }

    .section-image {
        margin-left: 0px;
        margin-right: 0px;
        max-width: 100%;
        height: 181px;
        border-radius: 5px;

    }

    .Home-panel2-imgs1,
    .Home-panel2-imgs2 {
        height: 176px;
        width: auto;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        padding-top: 40px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .image-container img {
        width: 93%;
        height: 53%;
        object-fit: cover;
        animation: fadeInTop 1s ease-out;
        border-radius: 8px;
    }

    .btn-for-slide {
        height: auto !important;
        padding: 5px !important;
    }

    .overlay {
        display: none;
    }

    .image-container {
        position: relative;
        width: auto;
        height: auto;
    }

    .Home-panel2-container {
        width: 100%;
        height: auto;
        text-align: center;
        font-size: 1.3em;
        margin: 0;
        color: #3ac5c8 !important;
        text-transform: uppercase;
    }

    .Home-panel3 {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 20px;
        align-items: center;
        gap: 10px;
    }

    .Home-panel3-box1 {
        background: #37bdc5;
        height: 371px;
        width: 95%;
        animation-delay: 0.2s;
    }

    .Home-panel3-box2 {
        display: block;
        justify-content: center;
        background-color: #343a40 !important;
        width: 95%;
        animation-delay: 0.4s;
    }

    .border-input {
        border: 3px solid rgb(177, 174, 174);
        background: transparent;
        color: rgb(179, 174, 174);
        font-size: 17px;
        padding: 18px;
        width: 100%;
        box-sizing: border-box;
        margin-left: 0px !important;
    }

    .Home-panel3-box2 button {
        font-size: 20px;
        background: linear-gradient(45deg, #3ac5c8 1%, #0b509e 100%);
        width: 100%;
        height: 55px;
        margin-left: 0px !important;
        margin-top: 20px;
    }

    .Home-panel3-box3 {
        background: #37bdc5;
        height: 430px;
        width: 95%;
        animation-delay: 0.6s;
    }


    /* -------------------------------------------responsive */
    .navbar-list>div {
        position: static;
        background-color: #3ac5c8;
        box-shadow: 0 0 0 0;
    }


    .nav-relative {
        background: #3ac5c8;
    }

    .dropdown a {
        border-bottom: 1px solid white !important;
        color: white !important;
    }

    .Home-panel4 h1 {
        padding-top: 0px !important;
        width: 100%;
        font-size: 25px;
        margin: 0 !important;
        color: #3ac5c8 !important;
        padding-left: 0px !important;
        text-transform: uppercase;
    }

    .Home-panel4 {
        height: auto !important;
    }

    .Home-panel4 p {
        color: #9c9a9a;
        font-size: 15px;
        width: 100%;
        line-height: 25px !important;
        padding-right: 40px !important;
        padding-left: 40px !important;


    }

    .Home-panel5 {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        padding: 20px;
        width: 100%;
        margin-left: 0px !important;
    }

    .Home-panel5-box p {
        font-size: 15px;
        margin: 5px 0;
        color: #757373;
    }

    .Home-panel5-box h2 {
        font-size: 1.3rem;
        margin: 10px 0;
        color: rgb(92, 90, 90);
    }

    .Home-panel5-box a {
        display: inline-block;
        margin-top: 10px;
        font-size: 15px;
        color: #3ac5c8 !important;
        text-decoration: none;
    }

    .nav-relative>div {
        display: block;
    }

    .home-panel6 {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
    }

    .home-panel6-box {
        width: 70%;
        padding: 15px;
        margin: 10px 0;
        text-align: center;
        color: white;
    }

    .Home-panel7 h2 {
        font-size: 2rem;
        margin-bottom: 20px;
        color: #333;
        color: #3ac5c8 !important;
    }

    .testimonial {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 20px;
    }

    .Home-panel3-box3 p {
        font-size: 19px;
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 35px;
        padding-top: 20px;
        color: rgb(233, 230, 230);
    }




    .Home-panel3-box1 p {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 1rem;
        line-height: 26px;
        padding-top: 20px;
        color: rgb(233, 230, 230);
    }

    .footer-col h3 {
        margin-bottom: 15px;
        font-size: 25px;
    }

    a {
        color: #fff;
        text-decoration: none;
        display: block;
        margin: 0px 0;
        font-size: 15px;
    }

    .mobile-sub-nav {
        background-color: white;
        color: #36bbc4 !important;
    }

    .p-mobile {
        color: #e5e7eb !important;
        font-size: 15px !important;
    }


    .nav-relative:hover>div {
        background-color: #36bbc4;
        position: relative;
        box-shadow: 0 0 0 0;
    }

    .nav-relative>div {
        background-color: #36bbc4;
        position: relative;
        box-shadow: 0 0 0 0;
    }

    .nav-links {
        position: fixed;
        height: 100vh;
        z-index: 200;
        background-color: white;
        top: 0px;
        right: 0;
        width: 45%;
        overflow: scroll;
        padding-bottom: 30px;
    }

    .nav-links li {
        display: inline;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #37bcc5;
    }

    .nav-links li a {

        line-height: 20px;
        font-size: 15px;
        border-bottom: 1px solid #36bbc4;

    }


    .About-panel1 {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        padding-top: 0px !important;
        margin-left: 0px !important;
        justify-content: center;
    }

    .left-section {
        padding-left: 30px;
        padding-right: 30px;
        text-align: left;
        opacity: 0;
        animation: fadeInLeft 1s ease-out forwards;
    }

    .About-panel1 p {
        font-size: 15px;
        color: #6b6969;
        line-height: 25px;
    }

    .About-panel1 h2 {
        margin-top: 20px;
        font-size: 27px;
        color: #248db4;
    }

    .right-section {
        display: flex;
        flex: 1 1;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        padding-bottom: 20px;
        width: 75%;

        img {
            width: 100% !important;
        }
    }

    .Products-penel1 {
        width: 100%;
        display: flex;
        margin-left: 0px !important;
        flex-wrap: wrap !important;
        /* gap: 10px; */
        padding: 20px;
    }

    .Products-penel1-button {
        background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-height: 200px;
        width: 135px;
        padding: 10px 20px;
        font-size: 20px;
        border: 1px solid #ccc;
        border-radius: 15px;
        cursor: pointer;
        transition: .5s ease;
    }

    .Service-penel1 p {
        font-size: 15px;
        color: #666;
        line-height: 1.6;
        margin-bottom: 20px;
    }

    .Service-penel1 h1 {
        font-size: 25px;
        margin-bottom: 15px;
        color: #1b6f8d;
    }

    .Service-penel1 th,
    .Service-penel1 td {
        border: 1px solid #c4c0c0;
        text-align: center;
        color: #248db4;
        font-size: 15px;
    }


    .Service-penel1 button {
        padding: 5px 10px !important;
        background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
        color: white;
        display: flex;
        border: none;
        border-radius: 5px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        cursor: pointer;
        font-size: 20px;
    }

    .service-btn {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow {
        font-size: 20px;
        margin: 0px !important;
    }

    .Portfolio-penel1 {
        display: flex;
        /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
        gap: 30px;
        padding: 0px !important;
        width: 100%;
        margin-left: 0px !important;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }

    .image-box {
        border: 1px solid #ccc;
        border-radius: 5px;
        overflow: hidden;
        text-align: center;
        width: 135px;
    }

    .Contact-penel1 {
        display: flex;
        gap: 60px;
        padding: 20px;
        margin-bottom: 80px;
        /* background-color: #f9f9f9; */
        /* border: 1px solid #ccc; */
        /* border-radius: 8px; */
        flex-direction: column;
    }

    .Contact-penel1-section {
        padding: 10px;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 8px;
        width: 100%;
        line-height: 40px;
    }

    .Contact-penel1 input,
    textarea {
        /* width: 100%; */
        height: 40px;
        margin: 10px 0;
        padding: 15px;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 15px !important;
    }

    .Contact-penel1-headingbox {
        h1 {
            font-size: 20px;
        }
    }

    .Contact-heading {
        h1 {
            font-size: 25px;
            font-weight: 600;
        }
    }

    .Contact-penel1 button {
        display: block;
        width: 100px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    .Contact-penel1-headingbox {
        height: 45px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(45deg, #0b509e 1%, #3ac5c8 100%);
        color: white;

        h1 {
            padding-top: 0px;
        }
    }

    .Contact-penel1-section {
        h2 {
            font-size: 20px;
            font-weight: 600;
            margin-top: 20px;
        }

        line-height: 30px;
    }

    .Contact-penel1-section {
        p {
            font-size: 16px;
        }
    }

    .footer2 {
        font-size: 18px;
    }


    .navbar-list>div {
        width: 170px !important;
        overflow: hidden;
    }
}


@media (max-width: 1200px) {
    .slider h2 {
        font-size: 48px;
    }

    .slider p {
        font-size: 20px;
    }

    .slide-content {
        padding: 80px;
    }

    .slider button {
        width: 150px;
        height: 45px;
    }
}

@media (max-width: 992px) {
    .slider h2 {
        font-size: 36px;
    }

    .slider p {
        font-size: 18px;
    }

    .slide-content {
        padding: 60px;
    }

    .slider button {
        width: 130px;
        height: 40px;
    }
}

@media (max-width: 768px) {
    .slider h2 {
        font-size: 28px;
    }

    .slider p {
        font-size: 16px;
    }

    .slide-content {
        padding: 40px;
    }

    .slider button {
        width: 120px;
        height: 35px;
    }
}

@media (max-width: 576px) {
    .slider {
        flex-direction: column;
        align-items: center;
    }

    .slide {
        flex-direction: column;
        align-items: center;
    }

    .slide-content {
        padding: 20px;
        text-align: center;
    }
}